body{
    background: #000;
    margin:0px;
    font: 100;
}
.font-style{
    font-weight: 600;
    color: aliceblue;
}
.lg-react-element{
    column-count: 3;
    column-gap: 10px;
}
img{
    max-width: 100%;
    display: block;
    padding: 5px 0px;
    border-radius: 20px;
    transition: transform 0.2s;
}
img:hover{
    filter: opacity(0.9);
    transform: scale(1.01);
}